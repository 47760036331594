import React from 'react'
import CoinMarket from './CoinMarket';
import Cards from '../Cards/Cards';

const Index = () => {
  return (
    <>
    {/* <BalanceCard/> */}
    
    {/* <PyramidData/> */}

    {/* <MobileMenu/> */}

    {/* <Cards/> */}

    <CoinMarket/>
    </>
  )
}

export default Index
