import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TransactionList from './TransactionList';

import WalletSlide from './WalletSlide';
import WalletButton from './WalletButton';
import CoinsPayment from './CoinsPayment';
import Markets from '../Home/Markets';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import Cards from '../Cards/Cards';
import CoinMarket from '../Home/CoinMarket';
const Wallet = () => {
  
  return (
    <div>
        <Cards/>
        <Container sx={{marginTop: '-50px'}}>
          {/* <Card elevation={1}  mx={0} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '15px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              
              <CardActions sx={{justifyContent: 'space-between'}}>

              <WalletButton/>        

              <WithdrawalButton/>

              </CardActions>
      
            </Box>
            
          </Card> */}
          {/* <Markets/> */}
        {/* <TransactionList/> */}
        <CoinMarket/>

        </Container>
    </div>
  )
}

export default Wallet
