import React, {useState, useEffect, Fragment} from 'react';
import Slider from "react-slick";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { useMutation } from "react-query";

import apiClient from "../../request/http-common";
import CreditCardIcon from '@mui/icons-material/CreditCard';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Cards = () => {
  
  const user = useSelector((state) => state.user.user);
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const coins = useSelector((state) => state.coins.coins);

  const [isFlipped, setFlipped] = useState(false);
    const [crdType, setCardType] = useState('');

    const getBTCbalance = () => {
      let bal = 0;
      Object.keys(coins).forEach(coin => {
        if (coins[coin].symbol === 'BTC') {
          bal = (fundingBal/coins[coin].price).toFixed(8) ;
        }
      });

      return bal;
    }

  const handleClick = (e) => {
      e.preventDefault();
      setFlipped(!isFlipped);
    }

    const initialCards = {
        number1: 'XXXX',
        number2: 'XXXX',
        number3: 'XXXX',
        number4: 'XXXX',
        name: 'XXXXX XXXXXX',
        expiry: 'XX/XX',
        cvv: 'XXX',
        type: 'visa'
    }

    const updateCradDetails = (card) => {
        setCurrentCard({...currentCard, 
            number1: card.number1,
            number2: card.number2,
            number3: card.number3,
            number4: card.number4,
            name: user.name,
            expiry: '08/2029',
            cvv: '745',
            type: card.card_type
        })
    }

    const [currentCard, setCurrentCard] = useState(initialCards);
    const getDate = (date) => new Date(date).toDateString();

    useEffect(() => {

      setTimeout(() => {
        setFlipped(true);
      }, 3000);

      setTimeout(() => {
        setFlipped(false);
      }, 5000);


    }, []);

  return (
    <div>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={5}>
        <Card sx={{marginTop: '75px', paddingX: '10px'}} elevation={0}>
        <div>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                <Box>
                <Card onClick={handleClick} sx={{marginX: '5px', height: '220px', paddingBottom: '0px', borderRadius: '10px'}}>
                    <Typography px={2} mb={-5} py={1}  sx={{display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                        
                        <Typography sx={{color: '#fff', fontSize: 18, fontWeight: 'bolder'}}  variant="h5" color="text.secondary">
                            ACCOUNT BALANCE
                        </Typography>

                        <img style={{height: '40px', marginTop: '-15px', transform: 'translate(0px, 10px)'}} src="https://res.cloudinary.com/dvoynhghq/image/upload/v1715094612/cropped-contentos-192x192_kux4uq.png"/>
                        
                    </Typography>

                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height: '220px' }}
                        image="https://res.cloudinary.com/dvoynhghq/image/upload/v1716022648/contentos_ffzzsa.png"
                        
                        alt="wallet"
                        />
                     

                    <Box sx={{display: 'flex', textAlign: 'left', paddingX: '20px', justifyContent: 'space-between', color: '#fff', fontSize: 18, marginTop: '-50px'}}  variant="h5" color="text.secondary">
                        
                        <Typography variant="caption">
                            Asset Holder Name
                            <Typography variant="subtitle1" display="block" gutterBottom>
                                {user.name}
                            </Typography>
                        </Typography> 

                        <Typography variant="caption">
                            Access Code
                            <Typography variant="subtitle1" display="block" gutterBottom>
                            {user.referral_code}
                            </Typography>
                        </Typography>

                    </Box>  

                     <Typography sx={{ paddingX: '20px', color: '#fff', fontWeight: 'bolder', fontSize: 28, marginTop: '-130px'}}  variant="h5" color="text.secondary">
                        {formatPrice(fundingBal)}
                    </Typography>               
                </Card>
                </Box>

                <Box>
                <Card onClick={handleClick} sx={{marginX: '5px', height: '220px', borderRadius: '10px'}}>
                    <Typography px={2} py={1}  sx={{marginBottom: '-75px', display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                        
                        <Typography sx={{color: '#fff', fontWeight: 'bolder', fontSize: 18, textAlign: 'left'}}  variant="h5" color="text.secondary">
                            BITCOIN BALANCE
                            <Typography variant="button" display="block" gutterBottom>
                            {getBTCbalance()} BTC
                            </Typography>
                        </Typography>
                    </Typography>
                    <CardMedia
                            component="img"
                            sx={{ width: '100%', height: '230px' }}
                            image="https://res.cloudinary.com/dvoynhghq/image/upload/v1716022648/contentosback_frgojg.png"
                            alt="wallet"
                        />
                        {/* <Typography mb={-6} sx={{color: '#000000', textAlign: 'left', fontWeight: 'bold', transform: 'translate(0px, -120px)', bgcolor: '#fff', boxShadow: 2, width: 'fit-content', paddingY: '5px', paddingX: '9px', fontSize: 24}}  variant="h5" color="primary">
                    {formatPrice(fundingBal)}
                        </Typography> */}
                </Card>
                </Box>
            </ReactCardFlip>
        </div>
      </Card>
      
      </Grid>

      </Grid>

    </div>
  )
}

export default Cards
